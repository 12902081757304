<template>
  <div class="container-fluid mx-0 px-0">
      <div class="bg-img-sistem">
        <p class="title text-white div-a2" data-aos="fade-up">
          {{ textos_sistem_colab.seccion_1_titulo_sistem_colab }}
        </p>
      </div>
     
      <div class="container-fluid text-justify my-5 px-0 mx-0">
        <!-- titulo -->
        <div class="container">
          <p class="ml-md-4 h2 "><b>{{ textos_sistem_colab.seccion_2_descripcion_sistem_colab }}</b></p>     
        </div>  
        <!-- subtitulo -->
          <div class="container py-5">
            <div class="row check-sub contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 mt-2 text-right check-blue">
                <b-icon icon="check-square" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
              </div>
              <div class="col-sm-12 col-md-11 pl-0 ml-0 check-sub check-sub-it parrafo_no_margin_container">
                <p class="my-2 h3 parrafo_no_margin" id="padding_0"><b>{{textos_sistem_colab.seccion_2_listItem_1_sistem_colab}}</b></p><br>
              </div>
            </div>
            <!-- item -->
            <div class="row pl-md-5 pl-3 contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 py-2 text-right mt-2">
                <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky check-blue"></b-icon>
              </div>
              <div class="col-md-11 pl-0 ml-0 parrafo_no_margin_container">
                <p class="my-2 ml-3 parrafo_no_margin">{{textos_sistem_colab.seccion_2_listItem_2_sistem_colab}}</p><br>
              </div>
            </div>
            <div class="row pl-md-5 pl-3 contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 py-2 text-right mt-2">
                <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky check-blue"></b-icon>
              </div>
              <div class="col-md-11 pl-0 ml-0 parrafo_no_margin_container">
                <p class="my-2 ml-3 parrafo_no_margin">{{textos_sistem_colab.seccion_2_listItem_3_sistem_colab}}</p><br>
              </div>
            </div>
            <!-- <div class="row pl-md-5 pl-3 contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 py-2 text-right mt-2">
                <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky check-blue"></b-icon>
              </div>
              <div class="col-md-11 pl-0 ml-0">
                <p class="my-2 ml-3">{{textos_sistem_colab.seccion_2_listItem_4_sistem_colab}}</p><br>
              </div>
            </div> -->
            <!-- <div class="row pl-md-5 ml-md-5 pl-3 ml-3 contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 py-2 text-right">
                <b-icon icon="circle-fill" scale="0.8"  class="mr-4 color-blue-sky check-blue"></b-icon>
              </div>
              <div class="col-md-11 pl-0 ml-0">
                <p class="my-2">{{textos_sistem_colab.seccion_2_listItem_5_sistem_colab}}</p><br>
              </div>
            </div> -->
            <!-- <div class="row pl-md-5 ml-md-5 pl-3 ml-3 contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 py-2 text-right">
                <b-icon icon="circle-fill" scale="0.8" class="mr-4 color-blue-sky check-blue"></b-icon>
              </div>
              <div class="col-md-11 pl-0 ml-0">
                <p class="my-2">{{textos_sistem_colab.seccion_2_listItem_6_sistem_colab}}</p><br>
              </div>
            </div> -->
            <!-- <div class="row pl-md-5 ml-md-5 pl-3 ml-3 contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 py-2 text-right">
                <b-icon icon="circle-fill" scale="0.8" class="mr-4 color-blue-sky check-blue"></b-icon>
              </div>
              <div class="col-md-11 pl-0 ml-0">
                <p class="my-2">{{textos_sistem_colab.seccion_2_listItem_7_sistem_colab}}</p><br>
              </div>
            </div> -->
          </div>

          <div class="bg-gray">
            <div class="container py-5">
              <div class="row check-sub contenedor_titulos_iconos">
                <div class="col-md-1 mr-0 pr-0 mt-2 text-right">
                  <b-icon icon="check-square" scale="2.5" class="mr-3 color-blue-sky check-blue"></b-icon>
                </div>
                <div class="col-md-11 pl-0 ml-0 parrafo_no_margin_container">
                  <p class="my-2 h3 parrafo_no_margin"><b>{{textos_sistem_colab.titulo_text_sistem_colab}}</b></p><br>
                </div>
              </div>
              <div class="row pl-md-5 pl-3 contenedor_titulos_iconos">
                <div class="col-md-1 mr-0 pr-0 py-2 text-right mt-2">
                  <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky check-blue"></b-icon>
                </div>
                <div class="col-md-11 pl-0 ml-0 parrafo_no_margin_container">
                  <p class="my-2 ml-3 parrafo_no_margin">{{textos_sistem_colab.seccion_2_listItem_8_sistem_colab}} </p><br>
                </div>
              </div>
            </div>
          </div>

          <div class="container py-5">
            <div class="row check-sub contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 mt-2 text-right">
                <b-icon icon="check-square" scale="2.5" class="mr-3 color-blue-sky check-blue"></b-icon>
              </div>
              <div class="col-md-11 pl-0 ml-0 padding_0_container">
                <p class="my-2 pl-2  h3 padding_0"><b>{{textos_sistem_colab.seccion_2_listItem_9_sistem_colab}}</b></p><br>
              </div>
            </div>
            <div class="row pl-md-5 pl-3 contenedor_titulos_iconos">
              <div class="col-md-1 mr-0 pr-0 py-2 text-right mt-1">
                <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky check-blue"></b-icon>
              </div>
              <div class="col-md-11 pl-0 ml-0 parrafo_no_margin_container">
                <p class="my-2 ml-3 parrafo_no_margin">{{textos_sistem_colab.seccion_2_listItem_10_sistem_colab}}</p><br>
              </div>
            </div>
          </div>
          
          
      </div>
  </div>
</template>

<script>
export default {
  data(){
      return{
        
        textos_sistem_colab:{
          // seccion_1_titulo_sistem_colab:'SISTEMAS DE COLABORACION',
          // seccion_2_descripcion_sistem_colab:'Nos encargamos de la gestión integral de su propiedad',
          // seccion_2_listItem_1_sistem_colab:'Gestión integral a 10% de comisión (el primer año)',
          // seccion_2_listItem_2_sistem_colab:'Nos encargamos de la gestión integral de su propiedad por un 10% de comisión sobre los ingresos del huésped sin contar la limpieza. El huésped paga directamente la limpieza a  Linetor.  ',
          // seccion_2_listItem_3_sistem_colab:'Registramos la propiedad en las principales plataformas de reservas (BOOKING AIRBNB principalmente). Todos los registros a nombre del propietario.  Todas las reservas se gestionan a través de estas plataformas y son ellas las que transfieren directamente los pagos a los propietarios. Mensualmente LINETOR factura al propietario la comisión, la limpieza y los mantenimientos realizados.',
          // seccion_2_listItem_4_sistem_colab:'Con un ejemplo se entenderá mejor: Si publicamos una propiedad con un precio de 100 euros por día más 50 de limpieza y cae una reserva de 10 días, el huésped pagaría 1050 euros. Estos se reparten : ',
          // seccion_2_listItem_5_sistem_colab:'17% sobre 1050 ( 178.50 €) descuenta la plataforma (booking o airbnb). El propietario recibiría en su cuenta bancaria 1050-178.50=871.5€',
          // seccion_2_listItem_6_sistem_colab:'Linetor le facturaría al propietario el 10% sobre 1000 mas 21 % sobre 100 (21€)  de iva más 50 de la limpieza total 100+50+21=171',
          // seccion_2_listItem_7_sistem_colab:'21 % sobre 100 (21€)  de iva total 100+50+21=171 se llevaría LINETOR  quedando el resto para el propietario total 689.40 €.',
          // seccion_2_listItem_8_sistem_colab:'Gestión integral pago por día ocupado. Linetor le pagará al propietario un precio fijo por la reserva. Este precio dependerá de la temporada del año. solo se pagarán aquellos días ocupados, Linetor se encargará el 100% gestión comercial, limpieza, lavandería y control de consumibles. Los otros gastos ya quedan a responsabilidad del propietario',
          // seccion_2_listItem_9_sistem_colab:'Gestión integral con contrato de arrendamiento de local de negocio.',
          // seccion_2_listItem_10_sistem_colab:' LINETOR alquila al propietario la vivienda con contrato  de alquiler de local de negocios. Con IVA y retención.',        
        },
        textos:{}
        
      }
    },
    async mounted(){
        this.$store.watch(
          (state) => [state.recarga].join(),
          async () => {
            // this.showItemsNumber();
            // this.refreshButton();
            this.textos = JSON.parse(localStorage.getItem('text'))
            await this.llenarCampos()
            console.log(this.textos)
          }
        );
        this.textos = JSON.parse(localStorage.getItem('text'))
        await this.llenarCampos()
      },
      methods:{
        async llenarCampos(){
          this.textos_sistem_colab={
          seccion_1_titulo_sistem_colab: this.textos.seccion_1_titulo_sistem_colab,
          seccion_2_descripcion_sistem_colab: this.textos.seccion_2_descripcion_sistem_colab,
          seccion_2_listItem_1_sistem_colab: this.textos.seccion_2_listItem_1_sistem_colab,
          seccion_2_listItem_2_sistem_colab: this.textos.seccion_2_listItem_2_sistem_colab,
          seccion_2_listItem_3_sistem_colab: this.textos.seccion_2_listItem_3_sistem_colab,
          seccion_2_listItem_4_sistem_colab: this.textos.seccion_2_listItem_4_sistem_colab,
          seccion_2_listItem_5_sistem_colab: this.textos.seccion_2_listItem_5_sistem_colab,
          seccion_2_listItem_6_sistem_colab: this.textos.seccion_2_listItem_6_sistem_colab,
          seccion_2_listItem_7_sistem_colab: this.textos.seccion_2_listItem_7_sistem_colab,
          seccion_2_listItem_8_sistem_colab: this.textos.seccion_2_listItem_8_sistem_colab,
          seccion_2_listItem_9_sistem_colab: this.textos.seccion_2_listItem_9_sistem_colab,
          seccion_2_listItem_10_sistem_colab: this.textos.seccion_2_listItem_10_sistem_colab,      
          titulo_text_sistem_colab: this.textos.titulo_text_sistem_colab,  
        },
          console.log('cards seccion 3', this.cards_seccion_5)


        }
      }
}
</script>

<style>
.div-a2 {
  padding-top: 190px;
  width: 66% !important;
  text-align: center !important;
  margin-left: 17%;
}
.bg-img-sistem {
  /* The image used */
  background-image: url("/img/4.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
 @media (max-width: 580px) {
  .padding_0_container .padding_0{
    padding-left: 0 !important;
  }
  .parrafo_no_margin_container .parrafo_no_margin{
    margin-left: 0 !important;
  }
 }
 @media screen and (max-width: 580px) {
  #paddin_0{
    padding-left: 0 !important;
  }
  .contenedor_titulos_iconos{
    display: grid !important;
    grid-template-columns:1fr !important;
  }
  
   .check-blue{
     display: none !important;
    visibility: hidden !important; 

}

   .check-sub{
    text-align: justify;
     margin-right: 0px !important;
    margin-left: 0px !important; 
    width: 100% !important;
    display:inline !important
   }

   .check-sub-it{
    text-align: justify;
     margin-right: 0px !important;
    margin-left: 0px !important; 
    width: 100% !important;
    display:inline !important
   }

}
@media (max-width:764px){
  .contenedor_titulos_iconos{
    display: grid !important;
    grid-template-columns: 45px 1fr;
  }
}
</style>