import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    url: "https://foxservice.net/api/",
    // url: "http://localhost:3002/api/",
    // urlClients:"https://service-linetor.onrender.com/",
    urlClients:"https://backendpropietarios.foxservice.net/",
    //urlClients:"http://localhost:2727/",
    token: null,
    user: null,
    showEditItemsModal: false,
    scrollKey: 0,
    ancla: "",
    recarga: 0,
    cerrarModal: false,
    numeroWhatsappKey: 0,
    recargafaqsKey: 0,
  },
  mutations: {
    async recargafaqs(state, payload) {
      console.log("en store... recargafaqs", payload);
      state.recargafaqsKey++;
    },
    async actualizanumeroWhatapp(state) {
      state.numeroWhatsappKey++;
    },
    async actualizaModal(state, payload) {
      state.cerrarModal = payload;
    },
    refreshRecarga(state, payload) {
      state.recarga++;
    },
    logout(state, payload) {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("facturas");
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    setToken(state, token) {
      localStorage.setItem("tk", token);
      state.token = token;
    },
    refreshShowEditItemsModal(state, payload) {
      state.showEditItemsModal = payload;
    },
    refreshScrollKey(state, payload) {
      //console.log('actualizando scroll')
      state.scrollKey++;
      state.ancla = payload;
    },
  },
  actions: {
    async onFileSelected(context, { filename, file }) {
      try {
        //const file = event.target.files[0];
        // let filename = Math.floor(Math.random() * 9999999999999 + 1);
        // filename = name+'_'+filename+ '.jpg';
        // console.log(filename)
        //console.log('subiendo archivo...')
        let formData = new FormData();
        formData.append("name", "my-picture");
        formData.append("file", file);
        let url = context.state.url + "subirarchivos2/" + filename;
        let opts = {
          method: "POST",
          body: formData,
        };
        let req = await fetch(url, opts);
        if (req.ok) {
          // this.$swal.fire({
          //   title: "Archivo subido correctamente!",
          //   icon: "success",
          // });
          return true;
        }
      } catch (error) {
        console.log("error in onfileselected", error);
        return false;
      }
    },
    async login(context, { data }) {
      
      try {
         const options = {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
        'user':data.email,
        'password': data.clave
    })
        };
        // let req = await fetch(
        //   context.state.url + "usuarios/autenticate",
        //   options
        // );
        // let res = await req.json();
        //console.log(res)
        //SERVICIO TEMPORAL PARA OBTENER DATOS
      
       let req = await fetch(this.state.urlClients, options)
       let res = await req.json()

        //context.state.token = res.token;
        //localStorage.setItem("tk", res.token);
        
        if (res.ok === false) {
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          alert(res.message);
          return;
        } else {

          //console.log('login')
          console.log(res.user)
          return res.user;
        }
      } catch (error) {
        console.error("error", error);
        return 0;
      }
    },
    async get(context, { path }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        let req = await fetch(context.state.url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          router.push("/");
        }
        if (res.error) {
          console.error(res.error);
          alert(res.mensaje);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error-->", error);
        return error;
      }
    },
    async post(context, { path, data }) {
      try {
        // let token = localStorage.getItem('tk');
        const options = {
          method: "POST",
          headers: {
            // 'access-token': token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.url + path, options);
        let res = await req.json();
        // if(res.mensaje == 'Token inválida'){
        //     router.push('/')
        // }
        // if(res.error){
        //   alert(res.error)
        // }
        // else{
        //   return res
        // }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async put(context, { path, data }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "PUT",
          headers: {
            "access-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          router.push("/");
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async delete(context, { path }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "DELETE",
          headers: {
            "access-token": token,
            "Content-Type": "application/json",
          },
        };
        let req = await fetch(context.state.url + path, options);
        //console.log(req)
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          router.push("/");
        }
        if (req.status != 200) {
          alert(req.error);
        } else {
          return req;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async fecthFacturas(context, {path}){
      let data = await fetch(path)
      let resp = await data.json()
      return resp.facturas
    }
  },
  modules: {},
});
