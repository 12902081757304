<template>     
    <div class="card col-md mx-md-2 mx-0 mt-4 px-0 card-shadow" :data-aos="(item.tipo=='gestion' ? 'fade-up' : 'fade')" :data-aos-duration="(item.tipo=='checkin_checkout' ? '1100': item.id == 1 ? '700' : item.id== 2 ? '1100' : '1500')">
        
        <img  v-if="item.img" class="card-img-top mx-0 " :style="(item-img== '' ? 'display:none;' : '')" :src="item.img">
        
        <div class="card-body"  >
            <h4  class="card-title mb-4 mt-2 px-3 h3">{{item.titulo}}</h4>
            <div v-html="item.descripcion" id="Nuestros_servicios" class="text-center px-2"></div>
           
            
        </div>        
        <div :style="(item.tipo!='plan_precio' ? 'display:none;': '')" class="pt-3 bg-primary">
            <p class="text-light"><b>{{item.precio}}</b></p>
        </div>
        <div  class="mb-4" :style="(item.button==null ? 'display:none;': '')">
            <button @click="$router.push(item.ruta)" class="btn btn-blue_linetor px-5 pb-2">{{item.button}}</button>
        </div>
    </div>         
</template>

<script>
export default {
    props: ["data"],
    data: () => ({
        className: "data",
        user: null,
        item: {tipo:'', img:''},
        
        
    }),
    mounted() {
         this.$store.watch(
            (state) => [state.recarga].join(),
            async () => {
                // this.showItemsNumber();
                // this.refreshButton();

                  if (!this.data.tipo) {
                    this.data.tipo = ''
                }
                //console.log('tipo---->', this.data)
                this.item = this.data
                this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
                if (this.theme) {
                    this.className = this.theme;
                }
                //console.log(this.textos)
            }
        );
        if(!this.data.tipo){
            this.data.tipo = ''
        }
        //console.log('tipo---->', this.data)
        this.item = this.data
        this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
        if (this.theme) {
        this.className = this.theme;
        }
    },
}
</script>

<style scoped>

.card-shadow{
    box-shadow: 0px 2px 3px #24242454;
}
.width{
    width: 33.33333333333333%;
}

</style>