import Vue from 'vue'
import App from './App.vue'
// import App from './components/Home1'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import loading from 'vuejs-loading-screen'

Vue.use(loading, {bg: '#DFDFDF',
  slot: `
      <div class="px-5 py-3 bg-gray-800 rounded">
        <h3 class="text-3xl text-black">   <b-spinner label="Loading..."></b-spinner>    &nbsp;Loading...</h3>
      </div>
    `,
})
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
