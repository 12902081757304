<template>
  <div class="bg-footer container-fluid py-3 footer-height">
    <div class="container-modify">
      <div class="container-content">
        <div class="container-content-aff">
          <div class="container-logos">
            <img src="/img/logo.png" alt="logo de linetor" />
            <div class="aff">
              <img class="p-2" width="50px" src="/img/icons/Airbnbscuadrado.png" alt="logo de airbnb" />
              <img class="p-2" width="50px" src="/img/icons/Bookingcuadrado.png" alt="logo de booking" />
              <img class="p-2" width="50px" src="/img/icons/Vrbocuadrado.png" alt="logo de vrbo" />
              <img class="p-2" width="50px" src="/img/icons/Trapadvisorcuadrado.png" alt="logo de Tripadvisor" />
              <img class="p-2" width="50px" src="/img/icons/expediacuadrado.png" alt="logo de expedia" />
              <img class="p-2" width="50px" src="/img/icons/agodacuadrado.png" alt="logo de agoda" />
            </div>
          </div>
          <span class="legal" style="line-height: 17px">{{ eslogan }}</span>
        </div>
        <div class="container-links">
          <ul class="lista_links">
            <li>
              <a class="text-white pointer" @click="$router.push('/#Nuestros_servicio')">{{ nuestros_servicios }}</a>
            </li>
            <!-- <li><a target="_blank" href="/#presupuesto">{{ presupuesto }}</a></li> 
            <li><a target="_blank" href="/#faq">{{ preguntas_respuestas }}</a></li>
            <li><a target="_blank" href="/#login_new">{{ login_text }}</a></li> -->
            <li>
              <a class="text-white pointer" @click="$router.push('presupuesto')">{{ presupuesto }}</a>
            </li>

            <li>
              <a class="text-white pointer" @click="$router.push('/tarifas')">{{ precios }}</a>
            </li>

            <li>
              <a class="text-white pointer" @click="$router.push('/faq')">{{ preguntas_respuestas }}</a>
            </li>
            <li>
              <a class="text-white pointer" @click="$router.push('/login_new')">{{ login_text }}</a>
            </li>
          </ul>
        </div>
        <div class="container-content-contact">
          <a href="mailto:admin@linetor.net"><i class="fa fa-envelope-o mr-2" aria-hidden="true"></i>admin@linetor.net</a>
          <br />
          <br />
          <!-- <p>{{ siguenos_en }}</p>
          <a>
            <a href="https://www.facebook.com/people/Linetor/100089923634195/" target="_blank" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="40px" height="40px">
              <path
                fill="white"
                d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"
              />
            </svg>
          </a> -->
          <!-- <a href="https://www.instagram.com/linetor.esp/" target="_blank" > -->
          <!-- <a>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 50 50">
              <path
                fill="white"
                d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"
              ></path>
            </svg>
          </a> -->
        </div>
        <div class="bg-footer container-legal justify-content-center">
          <div class="content-legal">
            <span>{{ copytwrite }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      precios: "",
      eslogan: "",
      nuestros_servicios: "",
      presupuesto: "",
      preguntas_respuestas: "",
      login_text: "",
      siguenos_en: "",
      copytwrite: "",
      terminos_condiciones: "",
      politica_privacidad: "",
      politica_cookies: "",
      aviso_legal: "",
      textos: {},
    };
  },
  mounted() {
    window.addEventListener("localStorageChanged", (event) => {
      this.textos = JSON.parse(event.detail.storage);
    });
    this.llenarCampos();
    this.$store.watch(
      (state) => [state.recarga].join(),
      async () => {
        // this.showItemsNumber();
        // this.refreshButton();

        await this.llenarCampos();
        //console.log(this.textos)
      }
    );
  },
  methods: {
    llenarCampos() {
      let textos = JSON.parse(localStorage.getItem("text"));

      this.eslogan = textos.footer_eslogan;
      this.nuestros_servicios = textos.footer_nuestros_servicios;
      this.presupuesto = textos.footer_presupuesto;
      this.preguntas_respuestas = textos.footer_qa;
      this.login_text = textos.login_title;
      this.siguenos_en = textos.footer_rrss;
      this.copytwrite = textos.footer_copywrite;
      this.terminos_condiciones = textos.footer_terminos_condiciones;
      this.politica_privacidad = textos.footer_politica_privacidad;
      this.politica_cookies = textos.footer_politica_cookies;
      this.aviso_legal = textos.footer_aviso_legal;
      this.precios = textos.precios_titulo;
    },
    goto(id) {
      if (id == 1) this.$router.push("/terms");
      if (id == 2) this.$router.push("/politics");
    },
  },
  watch: {
    textos(newV) {
      this.llenarCampos();
    },
  },
};
</script>

<style>
.footer-height {
  height: 100%;
}
.bg-footer {
  background-color: #05102e;
}
.cursor {
  cursor: pointer;
}
.container-modify {
  padding: 18px 18px;
  width: 100%;
  height: 100%;
}

.container-content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  height: 100%;
}
.container-content-contact {
  text-align: left;
}
.container-content-aff {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.container-logos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 8px;
}
.container-logos > img {
  max-width: 150px;
  max-height: 76px;
}
.aff {
  display: inline-block;
}
.legal {
  color: white !important;
  opacity: 0.8;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
}
.container-links {
  padding: 0 8px;
}
.container-links ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.container-links ul li {
  display: flex;
  list-style: none;
  margin-bottom: 8px;
  font-size: 13px;
  margin-bottom: 16px;
}

.container-content-contact > p {
  color: white !important;
  font-weight: bold;
  font-size: 12px;
}
hr {
  background-color: #007bff;
  opacity: 0.7;
  widows: 100%;
}
.container-legal {
  position: relative;
  height: 36px;
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  justify-content: start;
  align-items: center;
  border-top: 1px solid rgb(0 123 255 / 70%);
  margin-top: 12px;
}
.content-legal {
  display: flex;
  align-items: center;
  gap: 18px;
}
.content-legal > span {
  font-size: 14px;
  color: white !important ;
}
.content-legal ul {
  position: absolute;
  right: 0;
  display: flex;
  gap: 25px;
  padding: 0;
  margin: 0;
}
.content-legal ul li {
  list-style: none;
  font-size: 12px;
}
.content-legal ul li a {
  color: white !important;
  cursor: pointer;
  border-bottom: 1px solid rgb(255, 255, 255, 0);
  transition: border 100ms linear;
}
.content-legal ul li a:hover {
  border-bottom: 1px solid rgb(255, 255, 255, 1);
}
@media (max-width: 993px) {
  .lista_links {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    margin-top: 8px !important;
  }
  .container-content {
    max-height: 458px;
  }
  .container-content-aff {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-height {
    max-height: 545px;
  }
  .container-content {
    display: flex;
    flex-direction: column;
  }
  .legal {
    margin-bottom: 16px;
  }
  .container-content-contact {
    margin-bottom: 16px;
  }
  .container-legal {
    justify-content: center;
    margin-bottom: 164px;
  }
  .content-legal {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
  .content-legal ul {
    position: static;
  }
  a {
    text-decoration: none !important;
  }
}
@media (max-width: 520px) {
  .footer-height {
    height: 600px;
    max-height: 664px;
  }
  .container-logos {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 366px) {
    .footer-height {
      height: 709px;
      max-height: 723px;
    }
    .lista_links {
      display: grid !important;
      grid-template-columns: 1fr !important;
      margin-top: 8px !important;
    }
  }
}
</style>
