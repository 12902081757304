<template>
  
    <div  class=" col-sm-4">
        <div class="row">
            <div class="col-12  mt-2">
                <img :src="item.img" alt="Avatar" class="w" >
            </div>
        </div>
        <div class="container pt-3 mt-2">
            <h4>{{item.titulo}}</h4> 
            
        </div>
    </div>
        
        <!-- <div style="height:10px;" class="col-4">
            <img  class="img-fluid" src="https://th.bing.com/th/id/R.e0e6b1c36d0fad2370db26a281b7014c?rik=EHDudg%2f%2f4mgjqQ&pid=ImgRaw&r=0" alt="">
            <p class="h4">Mejora tus ingresos.</p>
        </div> -->
        
        
        
 
</template>

<script>
export default {
    props: ["data"],
    data: () => ({
        className: "data",
        user: null,
        item: {tipo:''},
        
        
    }),
    mounted() {
        this.$store.watch(
            (state) => [state.recarga].join(),
            async () => {
                // this.showItemsNumber();
                // this.refreshButton();

                
                //console.log(this.textos)
                
                if (!this.data.tipo) {
                    this.data.tipo = ''
                }

                this.item = this.data

                this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
                if (this.theme) {
                    this.className = this.theme;
                }
            }
        );
        console.log('SE VOLVIO A RENDERIZAR')
        if(!this.data.tipo){
            this.data.tipo = ''
        }

        this.item = this.data

        this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
        if (this.theme) {
        this.className = this.theme;
        }
    },
}
</script>

<style scoped>
.width{
    width: 33.33333333333333%;
}
.w{
    width:50% !important;
}
@media only screen and (max-width: 780px) {
    .w{
        width:40% !important;
    }
}
</style>