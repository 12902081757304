import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import sistem_colab from "../views/sistem_colab.vue";

import login_new from "../views/login_new.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },

  {
    path: "/sistem_colab",
    name: "sistem_colab",
    component: () => import("../views/sistem_colab.vue"),
  },
  {
    path: "/servicios_propietario",
    name: "servicios_propietario",
    component: () => import("../views/servicios_propietario.vue"),
  },

  {
    path: "/presupuesto",
    name: "presupuesto",
    component: () => import("../views/presupuesto.vue"),
  },
  {
    path: "/ejemplo",
    name: "ejemplo",
    component: () => import("../views/ejemplo.vue"),
  },

  {
    path: "/tarifas",
    name: "tarifas",
    component: () => import("../views/Tarifas.vue"),
  },

  {
    path: "/planes",
    name: "planes",
    component: () => import("../views/planes.vue"),
    
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/faq.vue"),
  },

  {
    path: "/login_new/",
    name: "login_new",
    component: login_new,
  },
  { 
    path: "/politicas",
    name: "politicas",
    component: () => import("../views/Politicas.vue"),
  },
  {
    path: "/panel",
    name: "panel",
    component: () => import("../views/panel/Index.vue"),
    children: [
      {
        path: "LINETOR_Profile",
        name: "LINETOR_Profile",
        component: () => import("../views/panel/LINETOR_Profile.vue"),
      },
      {
        path: "LINETOR_account",
        name: "LINETOR_account",
        component: () => import("../views/panel/LINETOR_account.vue"),
      },
      {
        path: "LINETOR_Profile/LINETOR_Reservas",
        name:"LINETOR_Reservas",
        component: () => import("../views/panel/LINETOR_Reservas.vue")
      }

      /*{ path: 'recuperar_pass', 
          name: 'recuperar_pass', 
          component: () => import('../views/recuperar_pass.vue') },*/
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
