<template>
  <div id="app">
    <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    <Navbar style="position: absolute" />
    <router-view />

    <Footer />
    <div class="navbar-fixed-bottom footer text-left pl-2 zindexwhatsapp">
      <a
        :href="'https://wa.me/'+numerowhatsapp"
        target="_blank"
        class="btn btn-success btn-circle btn-xl"
      >
        <h1><i class="fa fa-whatsapp"></i></h1>
      </a>
      <!-- <button href="" type="button" class="btn btn-success btn-circle btn-xl  " id="example1"><i class="fa fa-whatsapp h1"></i></button> -->
    </div>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      scrollPosition: null,
      numerowhatsapp: "",
    };
  },
  async mounted() {
   
    //console.log("mounted in app");
    this.$store.watch(
      (state) => [state.numeroWhatsappKey].join(),
      () => {
        this.numerowhatsapp = localStorage.getItem("numerowhatsapp");
        //console.log('************', this.numerowhatsapp)
      }
    );
    // let ip = await fetch('https://api.ipify.org?format=json')
    // .then(x => x.json())
    // .then(({ ip }) => {
    //     // alert(ip);
    //     return ip
    // });

    // let pais = await fetch('https://api.ipregistry.co/?key=tryout')
    // .then(function (response) {
    //     return response.json();
    // })
    // .then(function (payload) {
    //     console.log(payload.location.country.name + ', ' + payload.location.city);
    //     return payload.location.country.name
    // });

    // alert(pais)
    // let id
    // if(pais== 'Nicaragua' || pais == 'España'){
    //   id = 1
    // }

    // // window.addEventListener('scroll', this.updateScroll)
    // let texto = JSON.parse(localStorage.getItem('text')) || {}
    // if(!texto.id){
    //   this.$isLoading(true)
    //   //await this.getData(id)
    //   this.$isLoading(false)
    // }
    // else{
    //   //this.$store.commit('refreshRecarga', 1)
    // }
  },
  methods: {
    async getData(id) {
      try {
        let req = await this.$store.dispatch("get", {
          path: "linetor_textos/" + id,
        });
        //console.log('res', req)
        if (req.id) {
          //console.log("ok");
          localStorage.setItem("text", JSON.stringify(req));
          this.$store.commit("refreshRecarga", 1);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    updateScroll() {
      // console.log('scroll ' , window.scrollY)
      // this.scrollPosition = window.scrollY
    },
  },
};
</script>
<style>
/* @import "./assets/css/styles.css"; */
body {
  font-family: "Quicksand", sans-serif;
  color: black !important;
}
#shadow-w {
  border: 1px solid;
  padding: 10px;
  box-shadow: 1px 10px 8px #2f2f2f60;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

/* @import "./assets/css/styles.css"; */
.btn-success {
  background-color: #1db856 !important;
  border-color: #25d366;
}
.btn-success:hover {
  background-color: #16a34a !important;
  border-color: #7e817f;
}
.btn-success:focus {
  background-color: #0a8a39 !important;
  border-color: #0cb148;
}

.footer {
  position: fixed;
  height: 90px;
  bottom: 0;
  width: auto;
}
/* @import "./assets/css/styles.css"; */

#app {
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#detail {
  padding: 0px;
  width: 100% !important;
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.pointer {
  cursor: pointer;
}
.zindexwhatsapp{
  z-index: 99999;
}
</style>
